import { io } from 'socket.io-client';

declare const process: {
  env: {
    REACT_APP_SERVER_URL: string;
  }
};

// Create websocket connection to Portlserver-env
const wsServer = process.env.REACT_APP_SERVER_URL.indexOf('localhost') >= 0 ? 'http://localhost:5000/streets' : `${process.env.REACT_APP_SERVER_URL}:5000/streets`;

export const socket = io(wsServer, {
  transports: ['websocket'],
  reconnection: true,
  timeout: 5 * 60 * 60, // Connection timeout before failing (default: 20000ms)
  autoConnect: true,
});

export default socket;
