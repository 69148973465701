import React from 'react';
import moment from 'moment';
import './style.scss';
import { getTempC } from '../helpers';
import { Orientation, useOrientationReducer } from '../../AdLoop/useOrientationReducer';

type TodayProps = {
  weatherIcon: string
  temperature: string;
  state: any;
  city: any;
};

const Today = (props: TodayProps): React.ReactElement => {
  const {
    weatherIcon, temperature, state, city,
  } = props;
  const [orientationState] = useOrientationReducer();

  const datee = moment().format('YYYY-MM-DD');

  const dateText = (
    <p className='date-text'>
      {moment().format('dddd, MMMM Do')}
    </p>
  );

  const temperatureText = (
    <>
      <p className='temperature-text'>
        {temperature || ''}
      </p>
      <p className='wi wi-celsius celsius' />
    </>
  );

  const feelsLikeText = (
    <>
      <p className='medium-text'>
        Feels Like:
        {' '}
        {state && state[datee]?.list[0] ? getTempC(state[datee]?.list[0]?.main?.feels_like) : ''}
      </p>
      <p className='wi wi-celsius tiny-celsius' />
    </>
  );

  const hourText = <p className='hour-text'>{moment().format('hh:mma')}</p>;

  const weatherStatus = (
    <div className='sky'>
      <p className='weather-status'>{state && state[datee]?.list[0] && state[datee]?.list[0]?.main ? state[datee]?.list[0]?.weather[0]?.main : ''}</p>
    </div>
  );

  const weatherCharacteristics = (
    <>
      <div className='flex'>
        <div>
          <p className='characteristic-text'>Pressure</p>
          <p className='characteristic-text'>Humidity</p>
          <p className='characteristic-text'>Wind Speed</p>
        </div>
        <div>
          <p className='characteristic-values-text'>
            {state && state[datee] ? state[datee]?.list[0]?.main?.pressure / 10 : ''}
            {' '}
            kPa
          </p>
          <p className='characteristic-values-text'>
            {state && state[datee] ? state[datee]?.list[0]?.main?.humidity : ''}
            %
          </p>
          <p className='characteristic-values-text'>
            {state && state[datee] ? state[datee]?.list[0]?.wind?.speed : ''}
            km/h
          </p>
        </div>
      </div>
      <div className='flex'>
        <div>
          <p className='characteristic-text'>Visibility</p>
          <p className='characteristic-text'>Sunrise</p>
          <p className='characteristic-text'>Sunset</p>
        </div>
        <div>
          <p className='characteristic-values-text'>
            {state && state[datee] ? state[datee]?.list[0]?.visibility / 100 : ''}
            km
          </p>
          <p className='characteristic-values-text'>
            {city && city.sunrise ? moment(city.sunrise * 1000).format('hh:mma') : ''}
          </p>
          <p className='characteristic-values-text'>
            {city && city.sunset ? moment(city.sunset * 1000).format('hh:mma') : ''}
          </p>
        </div>
      </div>
    </>
  );

  const landscapeToday = (
    <>
      <div className='first-row'>
        <div className='center'>
          {/* <i aria-hidden='true' className={`wi ${weatherIcon} icon-image`} /> */}
          <img className='icon-image' alt='weather icon' src={`./assets/wi/${weatherIcon}.svg`} />
        </div>
        <div>
          {dateText}
          <div className='flex'>
            {temperatureText}
            {feelsLikeText}
          </div>
        </div>
        {hourText}
      </div>
      <div className='first-row'>
        {weatherStatus}
        {weatherCharacteristics}
      </div>
    </>
  );

  const portraitToday = (
    <>
      {hourText}
      {dateText}
      <div className='weather-container'>
        <div className='display-center'>
          {/* <i aria-hidden='true' className={`wi ${weatherIcon} icon-image`} /> */}
          <img className='icon-image' alt='weather icon' src={`./assets/wi/${weatherIcon}.svg`} />
          {weatherStatus}
        </div>
        <div className='display-center'>
          <div className='flex'>
            {temperatureText}
          </div>
          <div className='flex'>
            {feelsLikeText}
          </div>
        </div>
      </div>
      <div className='characteristics-container'>
        {weatherCharacteristics}
      </div>
    </>
  );

  return (
    <div className='today'>{orientationState.orientation === Orientation.Landscape ? landscapeToday : portraitToday}</div>
  );
};

export default Today;
