import React, {
  ReactElement,
} from 'react';
import {
  Redirect, Route, Switch,
} from 'react-router-dom';
import './App.css';
import ClipLoader from 'react-spinners/ClipLoader';
import styled from 'styled-components';
import AdLoop from './views/AdLoop/AdLoop';
import LocationPrompt from './views/LocationPrompt';
import AwaitingApproval from './views/AwaitingApproval';
import UnAuth from './views/UnAuth';
import Debug from './views/Debug';
import { useGetDeviceInfo } from './hooks/useGetDeviceInfo';
import { useSocketEvents } from './hooks/useSocketEvents';
import { useDeviceId } from './hooks/useDeviceId';
import { useLocationPermission } from './hooks/useLocationPermission';
import { useOrientation } from './hooks/useOrientation';

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100vh;
  padding: 20% 5% 5%;
`;

const Uuid = styled.div`
  position: absolute;
  bottom: 0
`;

const App: React.FC = (): ReactElement => {
  const { isLoading, device } = useGetDeviceInfo();
  const { showUuid } = useSocketEvents();
  const deviceId = useDeviceId();
  useLocationPermission();
  const { orientation, mongoRotate } = useOrientation({ device });

  if (isLoading && !device) {
    return (
      <LoadingContainer>
        <ClipLoader color='rgb(31, 182, 255)' size={50} />
      </LoadingContainer>
    );
  }

  return (
    <div className={`app ${orientation} deg-${mongoRotate}`}>
      <Switch>
        <Route exact path='/home'>
          <AdLoop device={device} />
        </Route>
        {/* <Route exact path='/login'> */}
        {/*   <Login id={deviceId} /> */}
        {/* </Route> */}
        <Route exact path='/debug'>
          <Debug />
        </Route>
        <Route exact path='/location-required'>
          <LocationPrompt />
        </Route>
        <Route exact path='/awaiting-approval'>
          <AwaitingApproval />
        </Route>
        <Route exact path='/unAuth'>
          <UnAuth />
        </Route>
        <Redirect to='/home' />
      </Switch>
      {showUuid && <Uuid>{deviceId}</Uuid>}
    </div>
  );
};

export default App;
