/* eslint-disable no-underscore-dangle */
import axios from 'axios';
// import { v4 as uuidv4 } from 'uuid';
import {
  axiosConfig, directAdsBlacklist, strapiUrl, streetsUrl,
} from '../../config';
import {
  AdItem, AdLocation, AdType, ImgOrVidFileExts,
} from '../../types';
// eslint-disable-next-line import/no-cycle
import {
  getWeightedAdArray,
  isAdProvider, isWithinCircle,
} from '../../utils/helpers';
// import { getLocalNews, LocalNewsProps } from '../../utils/api';
// eslint-disable-next-line import/no-cycle
import { getHiveStackUUID, getProviderAdsRequest } from './api';

// Api Error
type ApiError = {
  error: unknown;
};

// Common
export type MediaFile = {
  blobURL: string;
  _type: string;
};

// Get Strapi Ads
type StrapiAdsResponse = {
  data: AdItem[];
};

type GetStrapiAds = StrapiAdsResponse;

const getStrapiAds = async (): Promise<GetStrapiAds> => {
  try {
    const response: StrapiAdsResponse = await axios(`${strapiUrl}/streets-loop-items`, {
      withCredentials: false,
    });

    if (response.data.length === 0) {
      console.error('No ads from Strapi');
      return { data: [] };
    }

    const ads = response.data.filter(({ Active }) => Active);

    if (ads.length === 0) {
      console.error('No active ads from Strapi');
      return { data: [] };
    }

    return { data: ads };
  } catch (error) {
    console.error('Error fetching ads from Strapi', error);
    return { data: [] };
  }
};

// Get Strapi Presets Ads

type StrapiPresetsResponse = {
  data: {
    id: number;
    Name: string;
    Location: AdLocation[];
  }[]
};

type GetStrapiPresets = StrapiPresetsResponse;

const getStrapiPresets = async (): Promise<GetStrapiPresets> => {
  try {
    const response: StrapiPresetsResponse = await axios(`${strapiUrl}/preset-locations`, {
      withCredentials: false,
    });

    return { data: response.data };
  } catch (error) {
    console.error('Error fetching presets from Strapi', error);
    return { data: [] };
  }
};

// Get Filtered Ads
type GetAds = {
  data: AdItem[];
};

const getAds = async (): Promise<GetAds> => {
  const [strapiAds, strapiPresets] = await Promise.all([getStrapiAds(), getStrapiPresets()]);

  if (strapiAds.data.length === 0) {
    return { data: [] };
  }

  const ads = strapiAds.data.filter(({ Location: location }) => {
    try {
      if (!Array.isArray(location) || location.length === 0) {
        return true;
      }

      if (strapiPresets.data.length > 0 && location[0].preset) {
        for (let i = 0; i < strapiPresets.data.length; i += 1) {
          const { id, Location } = strapiPresets.data[i];

          if (id === location[0].preset[0].id && Location[0]) {
            return isWithinCircle(Location[0]);
          }
        }
      }

      return isWithinCircle(location[0]);
    } catch {
      return true;
    }
  });

  return { data: ads };
};

const mapCreativeTypeToMimeType = {
  png: 'image/png',
  jpeg: 'image/jpeg',
  gif: 'image/gif',
  webp: 'image/webp',
  mp4: 'video/mp4',
  mpeg: 'video/mpeg',
  mpg: 'video/mpg',
  quicktime: 'video/quicktime',
  webm: 'video/webm',
  mov: 'video/mov',
};

// Get Direct Ad

export type IAdLoop = {
  mediaFile: MediaFile;
  duration: number;
  uniqueId: string;
  createdAt: number; // use to remove from queue
  pop?: string;
  type: AdType;
  weight: number;
};

export const getStrapiAd = (ad: AdItem): IAdLoop | ApiError => {
  const isVideo = Object.keys(mapCreativeTypeToMimeType).includes(ad.CreativeType)
    && mapCreativeTypeToMimeType[ad.CreativeType].includes('video');

  return {
    uniqueId: `${ad.id}`,
    createdAt: new Date().getTime(),
    mediaFile: {
      _type: mapCreativeTypeToMimeType[ad.CreativeType],
      blobURL: ad.AssetUrlOrComponentName,
    },
    // TODO: for videos, duration was undefined
    duration: isVideo ? 5 : ad.PlayLength,
    pop: undefined,
    type: ad.Type,
    weight: ad.Frequency || 1,
  };
};

type CustomAdItem = {
  _id: string,
  type: ImgOrVidFileExts;
  url: string;
  duration?: number;
  frequency: number;
};

const getCustomAds = async (): Promise<IAdLoop[]> => {
  const deviceId = window.DeviceApi.getDeviceId();
  try {
    const customAds = await axios.get(`${streetsUrl}/device-custom-ads/${deviceId}`, axiosConfig);
    return customAds?.data.map((item: CustomAdItem) => {
      const {
        duration, type, url, _id: customAdId, frequency = 1,
      } = item;
      const isVideo = Object.keys(mapCreativeTypeToMimeType).includes(type)
        && mapCreativeTypeToMimeType[type].includes('video');

      return {
        uniqueId: customAdId,
        createdAt: new Date().getTime(),
        mediaFile: {
          _type: mapCreativeTypeToMimeType[type],
          blobURL: url,
        },
        // TODO: for videos, duration was undefined
        duration: isVideo ? 5 : duration,
        type: AdType.Custom,
        weight: frequency,
      };
    }) || [];
  } catch (error) {
    console.log(error);
    return [];
  }
};

type GetProviderAdsProps = {
  adType: AdType;
  count?: number;
  country: string;
};

export const getProviderAds = async ({ adType, count, country }: GetProviderAdsProps): Promise<IAdLoop[]> => {
  const deviceId = window.DeviceApi.getDeviceId();
  const width = 1080;
  const height = 1920;

  switch (adType) {
    case AdType.Hivestack: {
      const uuid = await getHiveStackUUID({ deviceId, country });
      if (!uuid) {
        throw new Error('Failed to retrieve Hivestack UUID');
      }
      return getProviderAdsRequest({
        deviceId: uuid, adType, count, data: { country },
      });
    }
    case AdType.Broadsign:
    case AdType.Vistar: {
      return getProviderAdsRequest({
        deviceId,
        adType,
        count,
        data: {
          width, height, country,
        },
      });
    }
    default: {
      throw new Error(`Unsupported ad type: ${adType}`);
    }
  }
};

export const delay = (ms: number): Promise<void> => new Promise((resolve) => { setTimeout(resolve, ms); });

// Get Init Queues

export type AdsQueue = {
  providerQueue: IAdLoop[]
  directQueue: IAdLoop[]; // placeholder queue
  customQueue: IAdLoop[]; // deviceId custom ads queue
  fallbackQueue: IAdLoop[]; // fallback ads
  componentQueue: AdItem[] // components
};

export type GetInitQueues = {
  // types: AdItem['Type'][];
  ads: AdsQueue
  adProviders: AdType[]
  // TODO: enable 'news'
  // newsQueue: GetNews; // placeholder queue
};

type GetInitQueuesProps = {
  country: string
};

export const getInitQueues = async ({ country }: GetInitQueuesProps): Promise<GetInitQueues | ApiError> => {
  const ads = await getAds();
  const customAds = await getCustomAds();

  if (ads.data.length === 0 && customAds.length === 0) {
    return { error: 'No ads from Strapi or custom ads' };
  }

  const providerQueue: IAdLoop[] = [];
  const directQueue: IAdLoop[] = [];
  const customQueue: IAdLoop[] = [];
  const fallbackQueue: IAdLoop[] = [];
  const componentQueue: AdItem[] = [];

  const providerCategories = ads.data.filter(({ Type }) => isAdProvider(Type)) || [];

  const providerResponse = await Promise.allSettled(providerCategories.map((adCategory) => getProviderAds({ adType: adCategory.Type, country })));
  providerResponse.forEach((res) => {
    if (res.status === 'fulfilled') providerQueue.push(...res.value);
  });

  const deviceId = window.DeviceApi.getDeviceId();
  if (country !== 'us' && !directAdsBlacklist.includes(deviceId)) {
    const directAds = ads.data.filter(({ Type }) => Type === AdType.Direct);
    if (directAds.length > 0) {
      const response = directAds.map((ad) => getStrapiAd(ad));

      const directs = response.filter((ad) => !('error' in ad)) as IAdLoop[];

      if (directs.length > 0) {
        const weightedAds = getWeightedAdArray(directs);
        // await replaceMediaUrl({ adsQueue: directs, index: 0 });
        directQueue.push(...weightedAds as IAdLoop[]);
      }
    }
  }

  const fallbackAds = ads.data.filter(({ Type }) => Type === AdType.Fallback);
  if (fallbackAds.length > 0) {
    const response = fallbackAds.map((ad) => getStrapiAd(ad));

    const fallbacks = response.filter((ad) => !('error' in ad)) as IAdLoop[];

    if (fallbacks.length > 0) {
      const weightedAds = getWeightedAdArray(fallbacks);
      // await replaceMediaUrl({ adsQueue: fallbacks, index: 0 });
      fallbackQueue.push(...weightedAds as IAdLoop[]);
    }
  }

  if (customAds.length > 0) {
    const weightedAds = getWeightedAdArray(customAds);
    // await replaceMediaUrl({ adsQueue: customAds, index: 0 });
    customQueue.push(...weightedAds);
  }

  if (country !== 'us') {
    const components = ads.data.filter(({ Type }) => Type === AdType.Component);
    if (components.length > 0) {
      componentQueue.push(...components);
    }
  }

  return {
    adProviders: providerCategories.map((adCategory) => adCategory.Type),
    // @ts-ignore
    // ads: adsQueues,
    ads: {
      providerQueue,
      directQueue,
      customQueue,
      fallbackQueue,
      componentQueue,
    },
  };
};

export type QueueWeights = {
  [AdType.Direct]: number,
  [AdType.Fallback]: number,
  [AdType.Custom]: number,
  [AdType.Component]: number,
  provider: number
};

export type QueueNotificationParameters = {
  threshold: number,
  cooldown: number
};

export type QueueConfig = {
  queueCap?: Record<string, number>,
  weights: QueueWeights,
  queueNotificationParameters?: QueueNotificationParameters
  providerPriorities: AdType[]
};
export const getQueueConfig = async (): Promise<QueueConfig | { error: unknown }> => {
  try {
    const response = await axios.get(`${streetsUrl}/queue-config`, axiosConfig);
    return response.data;
  } catch (error) {
    return { error };
  }
};
