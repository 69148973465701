import { Orientation, useOrientationReducer } from '../views/AdLoop/useOrientationReducer';
import { Device } from '../types';

const DEFAULT_SCREEN_ROTATION = 270;

export type UseOrientationProps = {
  device?: Device
};

type UseOrientationReturnType = {
  mongoRotate: number,
  orientation: Orientation
};

export const useOrientation = ({ device = {} }: UseOrientationProps): UseOrientationReturnType => {
  const [orientationState, dispatchOrientation] = useOrientationReducer();

  const mongoRotate = 'rotate' in device ? device.rotate : DEFAULT_SCREEN_ROTATION;
  if ((mongoRotate === 0 || mongoRotate === 180) && orientationState.orientation === Orientation.Portrait) {
    dispatchOrientation({ type: 'SET_ORIENTATION', payload: { orientation: Orientation.Landscape } });
  }

  return {
    mongoRotate: mongoRotate as number,
    orientation: orientationState.orientation,
  };
};
