import { useEffect, useLayoutEffect, useRef } from 'react';

function useInterval(callback: () => void, delay: number | null): void {
  const savedCallback = useRef(callback);

  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!delay && delay !== 0) {
      return;
    }
    const timerId = setInterval(() => savedCallback.current(), delay);

    // eslint-disable-next-line consistent-return
    return () => { clearInterval(timerId); };
  }, [delay]);
}

export default useInterval;
