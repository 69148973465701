import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { groupBy } from '../../utils/helpers';
import { AdProviders } from '../../types';
import { queues } from '../../config';

const Debug: FC = () => {
  const lastRefresh = localStorage.getItem('lastRefresh');
  const providerQueue = groupBy(queues.current?.providerQueue || [], 'type');
  const history = useHistory();

  useEffect(() => {
    const fallbackTimeout = setTimeout(() => history.goBack(), 60 * 1000);

    return () => clearTimeout(fallbackTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h3>
        Last refresh:
        {' '}
        {lastRefresh}
      </h3>
      <h3>
        Device uuid:
        {' '}
        {window.DeviceApi.getDeviceId()}
      </h3>
      <h3>
        App version:
        {' '}
        {window.DeviceApi.getAppVersion()}
      </h3>
      <h3>Queue counts:</h3>
      {AdProviders.map((provider) => (
        <span>
          {provider}
          {': '}
          {providerQueue[provider]?.length || 0}
          {' '}
        </span>
      ))}

    </div>
  );
};

export default Debug;
