import { FC, ReactElement } from 'react';
import styled from 'styled-components';
import Header from '../../components/Header';

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #272a2c;
  color: ${(props) => props.theme.colors.white};
  padding: 0px 25px;
  position: relative;
`;

const UnAuthText = styled.div`
  font-size: 74px;
  text-align: center;
`;

const UnAuth: FC = (): ReactElement => (
  <PageContainer>
    <Header />
    <UnAuthText>You are not authorized to use this device</UnAuthText>
  </PageContainer>
);

export default UnAuth;
