import type { ReactElement } from 'react';
import { useEffect } from 'react';
import ReactPlayer from 'react-player';
import type { MediaFile } from './requests';
import WeatherTile from '../Weather';
import NewsTile from '../News';
import { StrapiComponentType } from './useAdLoopReducer';

type StaticImageProps = {
  imageUrl: string;
  duration: number;
  onEnded(): void;
  onError: (err?: unknown) => void;
};

const StaticImage = (
  {
    imageUrl, duration, onEnded, onError,
  }: StaticImageProps,
): ReactElement => {
  useEffect(() => {
    const timerId = setTimeout(onEnded, (duration || 10) * 1000);
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <img
        src={imageUrl}
        height='100%'
        width='100%'
        alt='Sponsored Content'
        style={{ borderRadius: '5px' }}
        onError={onError}
      />
    </div>
  );
};

type FallbackProps = {
  onError: (err: unknown) => void;
};

const Fallback = ({ onError }: FallbackProps) => {
  useEffect(() => {
    onError('Fallback');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

// type WeatherProps = {
//   isWeather: boolean;
//   weather: any
// };
//
// type NewsProps = {
//   isNews: boolean;
//   news: any
// };

type AdPlayerProps = {
  mediaFile: MediaFile;
  duration: number;
  onEnded(): void;
  onError(error?: unknown): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentProps: any
};

function AdPlayer({
  mediaFile, duration = 15, onEnded, onError, componentProps,
}: AdPlayerProps): ReactElement {
  if (componentProps.componentType === StrapiComponentType.Weather) {
    return <WeatherTile onEnded={onEnded} weather={componentProps.component} />;
  }

  if (componentProps.componentType === StrapiComponentType.News) {
    return <NewsTile onEnded={onEnded} news={componentProps.component} />;
  }

  if (!mediaFile?.blobURL) {
    return (
      <Fallback onError={onError} />
    );
  }

  // eslint-disable-next-line no-underscore-dangle
  if (mediaFile._type && mediaFile._type.includes('image')) {
    return (
      <StaticImage
        imageUrl={mediaFile.blobURL}
        duration={duration}
        onEnded={onEnded}
        onError={onError}
      />
    );
  }

  return (
    <ReactPlayer
      url={mediaFile?.blobURL}
      playing
      controls={false}
      height='100%'
      width='100%'
      className='adVideoPlayer'
      onEnded={() => {
        // document.getElementsByTagName('video')[0].load();
        onEnded();
      }}
      volume={0}
      muted
      config={{ file: { attributes: { poster: '/assets/portl_error.png' } } }}
      onError={(error) => {
        console.log('player error', mediaFile.blobURL, error);
        onError();
      }}
      loop={false}
    />
  );
}

export default AdPlayer;
