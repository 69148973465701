import { CSSTransition, TransitionGroup } from 'react-transition-group';
import React from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

const ANIMATION_TIME = 700;

const TransitionContainer = styled.div`
  // CSS for transition
  & .transition-appear {
    opacity: 0;
  }
  & .transition-appear-active {
    opacity: 1;
    transition: opacity ${ANIMATION_TIME}ms ease;
  }
  & .transition-enter {
    position: absolute;
    width: 100%;
    opacity: 0;
  }
  & .transition-enter-active {
    opacity: 1;
    transition: opacity ${ANIMATION_TIME}ms ease;
  }
  & .transition-exit {
    opacity: 1;
  }
  & .transition-exit-active {
    opacity: 0;
    transition: opacity ${ANIMATION_TIME}ms ease;
  }`;

type TransitionProps = {
  children: React.ReactElement
};

const PortlTransition = ({ children }: TransitionProps): React.ReactElement => (
  <TransitionContainer>
    <TransitionGroup component={null}>
      <CSSTransition
        key={uuidv4()}
        timeout={ANIMATION_TIME}
        classNames='transition'
      >
        {children}
      </CSSTransition>
    </TransitionGroup>
  </TransitionContainer>
);

export default PortlTransition;
