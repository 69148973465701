import {
  useEffect, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Device, ErrorType } from '../types';
import { getDeviceInfoRequest } from '../utils/api';
import { useDeviceId } from './useDeviceId';

interface AuthErrorResponse extends ErrorType {
  error: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    response?: {};
  };
}

const getLocalAuthInfo = (deviceId: string): Device => (
  {
    id: deviceId,
    model: '',
    x: 1920,
    y: 1080,
    orientation: 'landscape',
    rotate: 270,
    lat: 43.6532,
    lng: -79.3832,
    name: 'name',
    createdOn: new Date(),
    approved: true,
  });

type UseGetDeviceInfoReturn = {
  isLoading: boolean,
  device?: Device
};

// eslint-disable-next-line import/prefer-default-export,@typescript-eslint/explicit-module-boundary-types
export const useGetDeviceInfo = (): UseGetDeviceInfoReturn => {
  const history = useHistory();
  const deviceId = useDeviceId();
  const [isLoading, setIsLoading] = useState(true);
  const [device, setDevice] = useState<Device>();

  // Authentication function
  const getDeviceInfo = async (): Promise<void | NodeJS.Timeout> => {
    let deviceResponse: Device | AuthErrorResponse;
    if (history.location.search) {
      deviceResponse = getLocalAuthInfo(deviceId);
    } else {
      deviceResponse = await getDeviceInfoRequest({ deviceId });
    }

    setIsLoading(false);
    return setDevice(deviceResponse);
  };

  // check for refreshes
  useEffect(() => {
    getDeviceInfo();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // lastly check approval status
    if (device && device.approved === false) {
      history.push('/awaiting-approval');
    }
  }, [device, history]);

  return {
    isLoading,
    device,
  };
};
