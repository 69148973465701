import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

export const useLocationPermission = (): void => {
  const history = useHistory();
  useEffect(() => {
    // if no deviceapi, return
    if (!window.DeviceApi) return;

    try {
      const hasLocation = window.DeviceApi.hasLocationPermissions();
      if (!hasLocation) {
        history.push('/location-required');
        return;
      }
    } catch (error) {
      console.error(error);
    }
  }, [history]);
};
