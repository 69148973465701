export interface User {
  _id?: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  createdOn?: Date;
}

export interface ErrorType {
  // eslint-disable-next-line @typescript-eslint/ban-types
  error: {};
}

export interface DeviceApi {
  hasLocationPermissions(): boolean;
  requestLocationPermissions(): void;
  getAndroidVersion(): number;
  getAppVersion(): string;
  getDeviceId(): string;
  getDeviceModel(): string;
  getDisplayHeight(): number;
  getDisplayWidth(): number;
}

export type ImgOrVidFileExts =
  | 'png'
  | 'jpeg'
  | 'gif'
  | 'webp'
  | 'mp4'
  | 'mpeg'
  | 'mpg'
  | 'quicktime'
  | 'webm'
  | 'mov';

export interface AdLocation {
  lat: number;
  lng: number;
  radius: number;
  preset?: { id: number }[];
}

export type Orientation = 'landscape' | 'portrait' | undefined | null;

export interface Device {
  id?: string;
  model?: string;
  x?: number;
  y?: number;
  orientation?: Orientation;
  rotate?: number;
  lat?: number;
  lng?: number;
  name?: string;
  createdOn?: Date;
  approved?: boolean;
}

export interface Account {
  _id?: number;
  devices?: Device[];
  createdOn?: Date;
  name?: string;
  queueCap?: number;
}

export interface Permission {
  _id?: number;
  accountId?: number;
  userId?: number;
  role?: string;
}

export type GetInfo = User & {
  accounts: Account[];
};

export interface DeviceLocation {
  lat?: number;
  lng?: number;
  error?: unknown;
}

export enum AdType {
  Direct = 'direct',
  Hivestack = 'hivestack',
  Broadsign = 'broadsign',
  Vistar = 'vistar',
  Component = 'component',
  Custom = 'custom',
  Fallback = 'fallback',
}

export const AdProviders = [AdType.Hivestack, AdType.Broadsign, AdType.Vistar];

export interface AdItem {
  id: number;
  Frequency: number;
  Name: string;
  PlayLength: number;
  Type: AdType;
  AssetUrlOrComponentName: string;
  CreativeType: ImgOrVidFileExts;
  Active: boolean;
  Location: AdLocation[];
}
