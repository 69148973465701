import { DefaultTheme } from 'styled-components';

export type Color = 'portlBlue' | 'white' | 'darkGrey';

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: {
      portlBlue: string;
      white: string;
      darkGrey: string;
    };
  }
}

const theme: DefaultTheme = {
  colors: {
    portlBlue: '#1FB6FF',
    white: '#FFFFFF',
    darkGrey: '#222222',
  },
};

export { theme };
