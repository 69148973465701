import { getFutureDay } from '../helpers';

export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const imgMap = {
  'clear sky': { day: 'wi-day-sunny', night: 'wi-night-clear' },
  'few clouds': { day: 'wi-day-sunny-overcast', night: 'wi-night-alt-partly-cloudy' },
  'scattered clouds': { day: 'wi-day-sunny-overcast', night: 'wi-night-alt-partly-cloudy' },
  'overcast clouds': 'wi-cloudy',
  'broken clouds': 'wi-cloud',
  'shower rain': 'wi-showers',
  'light rain': 'wi-showers',
  'moderate rain': 'wi-showers',
  rain: { day: 'wi-day-rain', night: 'wi-night-rain' },
  thunderstorm: { day: 'wi-day-thunderstorm', night: 'wi-night-thunderstorm' },
  snow: { day: 'wi-day-snow', night: 'wi-night-alt-snow' },
  'light snow': { day: 'wi-day-snow', night: 'wi-night-alt-snow' },
  mist: { day: 'wi-day-fog', night: 'wi-night-fog' },
  haze: { day: 'wi-day-fog', night: 'wi-night-fog' },
};

export const nextDays = [];
for (let i = 1; i <= 4; i += 1) {
  const day = getFutureDay(i);
  nextDays.push(day as never);
}
