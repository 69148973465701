import React, { ReactElement } from 'react';
import moment from 'moment/moment';
import { getTempC } from '../helpers';
import { Orientation, useOrientationReducer } from '../../AdLoop/useOrientationReducer';

type Weather = {
  min: string;
  max: string;
};

type NextDayProps = {
  day: string;
  weatherIcon: string
  weather: Weather
  state: any;
  noSeparator: boolean
};

const NextDay = ({
  day, weatherIcon, weather, state, noSeparator,
}: NextDayProps): ReactElement => {
  const [orientationState] = useOrientationReducer();
  const datee = moment().format('YYYY-MM-DD');

  const landscapeNextDay = (
    <div className='display-center'>
      <p className='day'>{day}</p>
      <div className='next-days-container'>
        <img className='small-icon-image' alt='weather icon' src={`./assets/wi/${weatherIcon}.svg`} />
        <div className='min-max-temp'>
          <p className='max-temperature'>{getTempC(weather?.max)}</p>
          <p className='min-temperature'>{getTempC(weather?.min)}</p>
        </div>
        <i className='wi wi-celsius small-celsius' />
      </div>
    </div>
  );

  const portraitNextDay = (
    <div className={`display-center next-day-item ${noSeparator ? '' : 'border-separator'}`}>
      <p className='day'>{day}</p>
      <div className='flex'>
        <img className='small-icon-image' alt='weather icon' src={`./assets/wi/${weatherIcon}.svg`} />
        <div className='min-max-temp'>
          <p className='max-temperature'>{getTempC(weather?.max)}</p>
          <i className='wi wi-celsius small-celsius' />
        </div>
      </div>
      <p className='next-day-weather-status'>{state && state[datee]?.list[0] && state[datee]?.list[0]?.main ? state[datee]?.list[0]?.weather[0]?.main : ''}</p>
    </div>
  );

  return <>{orientationState.orientation === Orientation.Landscape ? landscapeNextDay : portraitNextDay}</>;
};
export default NextDay;
