import { useRef } from 'react';
import { Article } from '../News/helpers';

export const componentInitialState = {
  weather: {
    description: undefined,
    temp: undefined,
    dayNight: undefined,
    error: true,
    weather: undefined,
    city: undefined,
    duration: 15,
    weight: 1,
  },
  news: {
    article: undefined as unknown as Article,
    duration: 15,
    error: true,
    weight: 1,
  },
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useComponentRef = () => useRef(componentInitialState);
