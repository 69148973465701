import { FC, ReactElement } from 'react';
import styled from 'styled-components';

const Header = styled.div`
  height: 100px;
  width: 100%;
  background-color: #272a2c;
  position: absolute;
  top: 0;
  left: 0;
`;

const Logo = styled.img`
  height: 90px;
  padding: 25px 10px 10px;
`;

const HeaderComp: FC = (): ReactElement => (
  <Header>
    <Logo src='/logo.webp' />
  </Header>
);

export default HeaderComp;
