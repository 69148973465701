import {
  FC,
  ReactElement,
} from 'react';
import styled from 'styled-components';
import ClipLoader from 'react-spinners/ClipLoader';

const LoadingContainer = styled.div`
  text-align: center;
`;

const LoadingSpinner: FC = (): ReactElement => (
  <LoadingContainer data-testid='loader'>
    <ClipLoader size={50} color='#1FB6FF' loading />
  </LoadingContainer>
);

export default LoadingSpinner;
