import {
  useEffect,
  useState,
  ReactElement,
  FC,
  useCallback,
} from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat'; // load on demand
import LoadingSpinner from '../../components/LoadingSpinner';
import QRCodeWithData from '../../components/QRCodeWithData';
import { getNth } from '../../utils/helpers';

dayjs.extend(AdvancedFormat); // use plugin

interface BackgroundProps {
  'data-testid': string
  src: string
}

const Background = styled.div<BackgroundProps>`
  width: 100%;
  height: 100vw;
  color: white;
  background: linear-gradient(to bottom, #272727, #363636);
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 80px;
`;

const Time = styled.div`
  display: flex;
  font-size: 72px;
  padding: 80px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  justify-content: space-between;
`;

const TextWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Image = styled.img`
  width: 100%;
  margin: 0 auto;
  border: 8px solid #169AFF;
`;

interface TitleProps {
  font: number;
}

const Text = styled.div<TitleProps>`
  line-height: 1.21;
  font-family: 'Montserrat';
  overflow: scroll;
  height: 500px;
  font-size: ${({ font }) => (font)}px;
`;

const Title = styled(Text)`
  padding-bottom: 80px;
  font-weight: 600;
`;

const Description = styled(Text)<TitleProps>`
  padding-top: 100px;
`;

const SubTitle = styled.p`
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 60px;
`;

const QRContainer = styled.div`
  padding: 10px;
  margin-top: 40px;
  width: 360px;
  height: 360px;
  background-color: white;
`;

const QRWarpper = styled.div`
  padding: 80px;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const QRText = styled.p`
  font-size: 50px;
  text-align: center;
  height: 60px;
`;

const QRTextBold = styled(QRText)`
  font-weight: bold;
`;

const TimeText = styled.span`
  padding-left: 30px;
`;

const Logo = styled.p`
  font-size: 80px;
  position: absolute;
  bottom: 0;
  right: 0;
  color: white;
  padding: 80px;
`;

const Blue = styled.span`
  color: #1fb6ff;
  font-weight: bold;
  letter-spacing: -0.96px;
`;

const NewsSpan = styled.span`
  font-weight: bold;
  letter-spacing: -0.96px;
`;

function isOverflown(element: HTMLElement) {
  return ((element.scrollHeight > element.clientHeight)
    || (element.scrollWidth > element.clientWidth));
}

type NewsTileProps = {
  onEnded: () => void,
  news: any
};

const NewsTile: FC<NewsTileProps> = ({ onEnded, news }): ReactElement => {
  const {
    article: headline, titleFont = 152, descriptionFont = 64, duration,
  } = news;
  const [titleFontState, setTitleFont] = useState<number>(titleFont);
  const [descriptionFontState, setDescriptionFont] = useState<number>(descriptionFont);

  const today = new Date();
  const dayString = today.toLocaleString('en-us', { weekday: 'long' });
  const monthString = today.toLocaleString('en-us', { month: 'long' });
  const dateOrdinal = `${today.getDate()}${getNth(today.getDate())}`;

  const fullDate = `${dayString}, ${monthString} ${dateOrdinal}`;
  const hour = today.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).replace(' ', '').toLowerCase();

  useEffect(() => {
    const timeout = setTimeout(onEnded, duration * 1000);

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(timeout);
    };

    // eslint-disable-next-line
  }, []);

  const measuredRefTitle = useCallback((element) => {
    const resizeTitleFont = () => {
      if (isOverflown(element)) {
        setTitleFont((prevSize) => prevSize - 2);
      }
    };

    if (element !== null) {
      resizeTitleFont();
    }

    // eslint-disable-next-line
  }, [titleFontState]);

  const measuredRefDescription = useCallback((element) => {
    const resizeTitleFont = () => {
      if (isOverflown(element)) {
        setDescriptionFont((prevSize) => prevSize - 2);
      }
    };

    if (element !== null) {
      resizeTitleFont();
    }

    // eslint-disable-next-line
  }, [descriptionFontState]);
  // cbc-news, the-globe-and-mail, politico,

  const hoursAgo = headline.publishedAt && parseInt(dayjs(headline.publishedAt).format('k'), 10);

  if (!headline.urlToImage) return <LoadingSpinner />;

  return (
    <Background data-testid='sports-news' src={headline.urlToImage}>
      <Time>
        <span>{fullDate}</span>
        <span>{hour}</span>
      </Time>
      <TextWrapper>
        {headline.title && (
        <Title ref={measuredRefTitle} id='news-title' font={titleFontState}>
          {headline.title}
        </Title>
        )}
      </TextWrapper>
      <Image alt='news' src={headline.urlToImage} />
      <TextWrapper>
        <div>
          {headline.source && (
            <SubTitle>
              {headline.source.name}
              <TimeText>{`${hoursAgo} hour${(hoursAgo && hoursAgo > 1) ? 's' : ''} ago`}</TimeText>
            </SubTitle>
          )}
        </div>
        {headline.description && (
        <Description ref={measuredRefDescription} id='news-description' font={descriptionFontState}>
          {headline.description}
        </Description>
        )}
      </TextWrapper>
      <Logo>
        <Blue>portl</Blue>
        <NewsSpan>news</NewsSpan>
      </Logo>
      <QRWarpper>
        {headline.url && (
        <>
          <QRTextBold>Scan</QRTextBold>
          <QRText>for article</QRText>
          <QRContainer>
            <QRCodeWithData
              name='NewsTile'
              size={340}
              redirectUrl={headline.url}
            />
          </QRContainer>
        </>
        )}
      </QRWarpper>
    </Background>
  );
};

export default NewsTile;
