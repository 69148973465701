import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import Header from '../../components/Header';

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #272a2c;
  color: ${(props) => props.theme.colors.white};
  padding: 0px 25px;
  position: relative;
`;

const ApprovalText = styled.div`
  font-size: 74px;
  text-align: center;
`;

const WaitText = styled.div`
  font-size: 55px;
  text-align: center;
  padding-top: 50px;
`;

const AwaitingApproval: FC = (): ReactElement => (
  <PageContainer>
    <Header />
    <ApprovalText>We&apos;re currently reviewing your installation.</ApprovalText>
    <WaitText>
      Hang tight! Someone from our team will get back to you shortly.
    </WaitText>
  </PageContainer>
);

export default AwaitingApproval;
