import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';

export const useDeviceId = (): string => {
  const history = useHistory();
  return useMemo(() => {
    const id = history.location.search ? history.location.search.split('?')[1] : window?.DeviceApi?.getDeviceId();
    window.DeviceApi.getDeviceId = () => id;
    return id;
  }, [history]);
};
