import {
  FC,
  ReactElement,
  useEffect,
  useState,
  memo,
} from 'react';
import QRCode from 'qrcode.react';
import { serverUrl } from '../../config';
import { DeviceLocation } from '../../types';
import { getLocation } from '../../utils/helpers';

interface QRCodeProps {
  name: string;
  size: number;
  redirectUrl: string;
}

const QRCodeWithData: FC<QRCodeProps> = ({
  name, size, redirectUrl,
}): ReactElement => {
  const [position, setPosition] = useState<DeviceLocation>();
  const redirect = encodeURIComponent(redirectUrl);

  useEffect(() => {
    let isMounted = true;
    const fetchPosition = async () => {
      const { lat, lng } = await getLocation();
      if (isMounted) setPosition({ lat, lng });
    };
    fetchPosition();

    return () => { isMounted = false; };
  }, []);

  const query = `redirect=${redirect}&email=${'portlStreets'}&position=${encodeURI(JSON.stringify(position))}`;

  return (
    <QRCode
      value={`${serverUrl}/campaign/${name}?${query}`}
      size={size || 160}
      level='M'
      renderAs='svg'
    />
  );
};

export default memo(QRCodeWithData);
