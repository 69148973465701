// @ts-nocheck

import React, { FC, useEffect } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import moment from 'moment';
import './WeatherTileComponents/style.scss';
import Today from './WeatherTileComponents/Today';
import NextDay from './WeatherTileComponents/NextDay';
import {
  imgMap, days, nextDays,
} from './WeatherTileComponents/SmallComponents';
import { getTempC } from './helpers';
import { Orientation, useOrientationReducer } from '../AdLoop/useOrientationReducer';

type WeatherTileProps = {
  onEnded(): void;
  weather: any
};

const WeatherTile: FC<WeatherTileProps> = ({ onEnded, weather: state }) => {
  const [orientationState] = useOrientationReducer();

  useEffect(() => {
    if (!state) return;

    const timeout = setTimeout(onEnded, state.duration * 1000);

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(timeout);
    };

    // eslint-disable-next-line
  }, [state]);

  const getImg = (description, dayNight) => {
    // description = 'snow';
    // dayNight = 'day';

    // description = 'broken clouds';
    // dayNight = 'wi-clouds';
    if (description in imgMap) {
      if (typeof imgMap[description] === 'string') {
        return imgMap[description];
      }

      return imgMap[description][dayNight];
    }

    return 'wi-cloudy';
  };

  const todayDate = moment().format('YYYY-MM-DD');

  const { sunrise, sunset } = state?.city;
  const now = new Date().getTime() / 1000;

  const isDaytime = now < sunset && now > sunrise;

  return (
    <div className={`weather-tile-container ${isDaytime ? 'weather-day' : 'weather-night'} weather-portrait`}>
      <span className='portl-logo'>portl</span>
      {
                state && state.weather ? (
                  <>
                    <Today
                      weatherIcon={
                                // eslint-disable-next-line max-len
                                getImg(state?.weather[0][todayDate]?.list[0]?.weather[0]?.description, state?.dayNight)
                            }
                      temperature={state?.weather[0][todayDate]?.list[0] ? `${getTempC(state?.weather[0][todayDate]?.list[0]?.main?.temp)}` : ''}
                      state={state?.weather[0]}
                      city={state && state?.city}
                    />
                    <div className='horizontal-divider' />
                    <div className='next-days-container'>
                      {nextDays.map((day, index) => {
                        const currentWeather = state?.weather?.[index + 1];
                        return (
                        // eslint-disable-next-line react/no-array-index-key
                          <React.Fragment key={index}>
                            <NextDay
                              day={days[day.getDay()]}
                              weatherIcon={getImg(currentWeather[Object.keys(currentWeather || {})]?.description, 'day')}
                              weather={currentWeather[Object.keys(currentWeather || {})[0]]}
                              state={state?.weather[0]}
                              noSeparator={index === nextDays.length - 1}
                            />
                            {index !== nextDays.length - 1 && orientationState.orientation === Orientation.Landscape && <div className='vertical-divider' />}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <div className='centered-loading'>
                    <ClipLoader
                      css='border-width: 4px'
                      size={65}
                      color='#efefef'
                      loading
                    />
                  </div>
                )
            }
    </div>
  );
};
export default WeatherTile;
