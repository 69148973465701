import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { StylesProvider } from '@material-ui/core/styles';
// import { ErrorBoundary } from 'react-error-boundary';
// import * as Sentry from '@sentry/react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { theme } from './themes';
import { DeviceApi } from './types';
// import { isLocal } from './config';
// import ErrorFallback from './views/ErrorFallBack';
// import { createZendeskTicket } from './utils/api';

declare global {
  interface Window {
    DeviceApi: DeviceApi;
  }
}

if (!window.DeviceApi) {
  window.DeviceApi = {
    hasLocationPermissions: () => true,
    requestLocationPermissions: () => {},
    getAppVersion: () => 'blah',
    // Serban staging tablet ID
    // getDeviceId: () => '8187f1f5-56cc-42fe-8b11-61574419fad9',
    // Portl prod office Chromecast TV ID
    getDeviceId: () => 'c4bc0061-e9f7-4d45-8ac6-f75264180090',
    getDisplayHeight: () => 1080,
    getDisplayWidth: () => 1920,
    getDeviceModel: () => 'someModel',
    getAndroidVersion: () => 10,
  };
}

// const handleGlobalError = (error: Error) => {
//   createZendeskTicket({
//     subject: error.message,
//     data: error.stack,
//     email: 'automated_streets@portlmedia.com',
//   });
//   // refresh page after 30s
//   setTimeout(() => window.location.replace(window.location.origin), 30000);
// };

// Sentry.init({
//   dsn: 'https://2faf3b90429641318f7353135458bbf3@o4504010516004864.ingest.sentry.io/4505109129789440',
//   integrations: [new Sentry.BrowserTracing()],
//   tracesSampleRate: 1.0,
// });

ReactDOM.render(
  <StrictMode>
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </StylesProvider>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
