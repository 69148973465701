import axios from 'axios';
import { axiosConfig, streetsUrl } from '../../config';

export type WeatherProps = {
  lat: number;
  lng: number
};

// eslint-disable-next-line import/prefer-default-export,@typescript-eslint/explicit-module-boundary-types
export const getWeather = async ({ lat, lng }: WeatherProps) => {
  const time = new Date().getTime() / 1000;

  try {
    const { data } = await axios.get(`${streetsUrl}/weather`, { ...axiosConfig, params: { lat, lng } });
    if (data) {
      const { formattedList, city } = data;

      const dayNight = (time >= city.sunrise && time <= city.sunset) ? 'day' : 'night';

      return {
        description: data.list[0] && data.list[0].weather[0] ? data.list[0].weather[0].description : '',
        dayNight,
        temp: data.list[0] && data.list[0].main ? data.list[0].main.temp : '',
        weather: formattedList,
        city,
      };
    }
    return { error: true };
  } catch (error) {
    return { error };
  }
};

export const getTempC = (temp: string): number => Math.round(((parseFloat(temp) - 32) * 5) / 9);

export const getFutureDay = (daysInAdvance: number): Date => {
  const day = new Date();
  day.setDate(day.getDate() + daysInAdvance);

  return day;
};
