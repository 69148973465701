import React, { useReducer } from 'react';

type SetOrientation = {
  orientation: Orientation
};

type Actions = { type: 'SET_ORIENTATION', payload: SetOrientation };

export enum Orientation {
  Portrait = 'portrait',
  Landscape = 'landscape',
}

export const orientationInitialState = {
  orientation: Orientation.Portrait,
};

export const orientationReducer = (state = orientationInitialState, action: Actions): typeof orientationInitialState => {
  switch (action.type) {
    case 'SET_ORIENTATION': {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
};

type UseOrientationReducerReturnType = [typeof orientationInitialState, React.Dispatch<Actions>];
export const useOrientationReducer = ():UseOrientationReducerReturnType => useReducer(orientationReducer, orientationInitialState);
