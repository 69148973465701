import axios from 'axios';
import { serverUrl } from '../../config';

export interface Article {
  urlToImage: string;
  url: string;
  title: string;
  publishedAt: string;
  source?: {
    name: string;
  }
}

export interface LocalNewsProps {
  error?: unknown;
  articles?: Article[];
}

export const getLocalNews = async (category?: string): Promise<LocalNewsProps> => {
  try {
    let dynamicUrl = '/news';
    if (category && category !== '') dynamicUrl += `/${category}`;

    const { data } = await axios.get(serverUrl + dynamicUrl, {
      withCredentials: false,
    });
    return data;
  } catch (error) {
    return { error };
  }
};
